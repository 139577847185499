<template>
    <div class="test">
        <div class="select-label" :class="value === null ? 'd-none' : null">
            <div class="px-1">
                {{ inputDescription }}
            </div>
        </div>
        <b-form-select
            id="select-conversion-channel"
            class="form-input select"
            :class="isSelectInputValid === false ? 'dropdownError' : null"
            name="conversionChannel"
            :value="value"
            @input="$emit('input', $event)"
            :options="options"
        >
            <template slot="first">
                <option :value="null" disabled>
                    {{ inputDescription }}
                </option>
            </template>
        </b-form-select>
    </div>
</template>

<script>
export default {
    name: "ZSelect",
    props: {
        error: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true
        },
        isSelectInputValid: {
            type: [Boolean, null],
            default: null
        },
        inputDescription: {
            type: String,
            default: ""
        },
        value: {
            type: [Number, String],
            default: ""
        }
    }
};
</script>

<style lang="scss" scoped>
.select-label {
    z-index: 2;
    font-size: 10px;
    font-weight: 500;
    transform: translate(12px, -5px);
    position: absolute;
    color: $comet;
    background-color: white;
}

.select {
    height: 44px;
    border: 1px solid $link-water;
    border-radius: 6px;
    padding-left: 16px;
    color: $comet;
    font-weight: 500;
    font-size: 12px;
    position: relative;

    &:focus {
        box-shadow: none;
    }
}
.dropdownError {
    border: 1px solid $medium-carmine;
}
</style>
