const GUADALAJARA = "Guadalajara";
const CDMX = "CDMX";
const MTY = "Monterrey";
const LEON = "León";
const QUERETARO = "Querétaro";
const SAN_LUIS_POTOSI = "San Luis Potosí";
const AGUASCALIENTES = "Aguascalientes";

const NUM_GUADALAJARA = 0;
const NUM_CDMX = 1;
const NUM_MTY = 2;
const NUM_LEON = 3;
const NUM_QUERETARO = 4;
const NUM_SAN_LUIS_POTOSI = 5;
const NUM_AGUASCALIENTES = 6;

const options = [
    { value: NUM_GUADALAJARA, text: GUADALAJARA },
    { value: NUM_CDMX, text: CDMX },
    { value: NUM_MTY, text: MTY },
    { value: NUM_LEON, text: LEON },
    { value: NUM_QUERETARO, text: QUERETARO },
    { value: NUM_SAN_LUIS_POTOSI, text: SAN_LUIS_POTOSI },
    { value: NUM_AGUASCALIENTES, text: AGUASCALIENTES }
];

export default {
    options,
    NUM_GUADALAJARA,
    NUM_CDMX,
    NUM_MTY,
    NUM_LEON,
    NUM_QUERETARO,
    NUM_SAN_LUIS_POTOSI,
    NUM_AGUASCALIENTES,
    GUADALAJARA,
    CDMX,
    MTY,
    LEON,
    QUERETARO,
    SAN_LUIS_POTOSI,
    AGUASCALIENTES
};
