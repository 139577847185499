<template>
    <b-container id="pre-registration" fluid>
        <b-row>
            <b-col
                id="side-section"
                cols="12"
                md="4"
                class="text-left text-white p-4 px-sm-5 p-lg-5 d-flex align-items-end"
            >
                <div>
                    <p id="side-title" class="mt-sm-5">La solución a tus envíos locales</p>
                    <div class="z-divider mb-4 mt-lg-5"></div>
                    <div
                        class="d-flex justify-content-between justify-content-sm-start justify-content-lg-between flex-md-column flex-lg-row"
                    >
                        <div class="mr-sm-3">
                            <img src="@/assets/img/logo-walmart-white.png" alt="walmart" />
                        </div>
                        <div class="mr-sm-3 mt-md-3 mt-lg-0">
                            <img src="@/assets/img/logo-fragua-white.png" alt="fragua" />
                        </div>
                        <div class="mr-sm-3 mt-md-3 mt-lg-0">
                            <img src="@/assets/img/logo-liverpool-white.png" alt="liverpool" />
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col class="py-4 px-sm-5" cols="12" md="8">
                <b-row class="justify-content-lg-center h-100">
                    <b-col lg="8" xl="6" class="pt-lg-3">
                        <div class="d-flex flex-column justify-content-between h-100">
                            <div>
                                <div class="text-left mb-4">
                                    <router-link to="/">
                                        <img
                                            src="@/assets/img/logo.png"
                                            alt="zubut logo"
                                            class="brand-image"
                                        />
                                    </router-link>
                                </div>
                                <h1 class="z-text-big text-left font-weight-bold mt-md-5 mt-mb-4">
                                    Quiero más información sobre los servicios de Zubut
                                </h1>
                                <b-form @submit.prevent="handleSubmit" class="mt-md-4">
                                    <b-row>
                                        <b-col>
                                            <b-alert
                                                :show="showErrorAlert"
                                                variant="danger"
                                                class="z-text-smaller"
                                            >
                                                {{ errorMessage }}
                                            </b-alert>
                                            <b-alert
                                                :show="showSuccessAlert"
                                                variant="success"
                                                class="z-text-smaller"
                                            >
                                                Mensaje enviado. En breve te contactaremos.
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <z-input
                                                class="form-input"
                                                v-model="name"
                                                label="Nombre(s)"
                                                :state="isInputNameValid"
                                                type="text"
                                                id="name-input"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <z-input
                                                class="form-input"
                                                v-model="email"
                                                label="Correo electrónico"
                                                :state="isInputEmailValid"
                                                name="email"
                                                type="email"
                                                id="name-email"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <z-input
                                                class="form-input"
                                                v-model="phone"
                                                label="Celular o teléfono"
                                                type="tel"
                                                :state="isInputPhoneValid"
                                                id="name-phone"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <z-select
                                                v-model="conversionChannel"
                                                inputDescription="¿Dónde escuchaste de nosotros?"
                                                :options="conversionChannelOptions"
                                                :isSelectInputValid="
                                                    isSelectInputConversionChannelValid
                                                "
                                                class="form-input"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <z-select
                                                v-model="businessLine"
                                                inputDescription="Giro de tu empresa"
                                                :options="businessLineOptions"
                                                :isSelectInputValid="isSelectInputBusinessLineValid"
                                                class="form-input"
                                            />
                                        </b-col>
                                        <b-col cols="12">
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                                label="Tipo de servicio"
                                                label-align="left"
                                                label-class="z-label pb-1"
                                                class="text-left form-input"
                                            >
                                                <b-form-checkbox-group
                                                    id="checkbox-group-1"
                                                    v-model="serviceTypes"
                                                    :aria-describedby="ariaDescribedby"
                                                    name="flavour-1"
                                                >
                                                    <b-row>
                                                        <b-col
                                                            v-for="typeOption in ServiceType.options"
                                                            :key="typeOption.text"
                                                            cols="12"
                                                            sm="6"
                                                        >
                                                            <b-form-checkbox
                                                                :value="typeOption.text"
                                                                class="z-checkbox"
                                                            >
                                                                {{ typeOption.text }}
                                                            </b-form-checkbox>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                v-slot="{ ariaDescribedby }"
                                                label="Ciudades"
                                                label-align="left"
                                                label-class="z-label pb-1"
                                                class="text-left"
                                            >
                                                <b-form-checkbox-group
                                                    id="checkbox-group-2"
                                                    v-model="serviceCities"
                                                    :aria-describedby="ariaDescribedby"
                                                    name="serviceCities"
                                                >
                                                    <b-row>
                                                        <b-col
                                                            v-for="cityOption in ServiceCities.options"
                                                            :key="cityOption.text"
                                                            cols="12"
                                                            sm="6"
                                                        >
                                                            <b-form-checkbox
                                                                :value="cityOption.text"
                                                                class="z-checkbox"
                                                            >
                                                                {{ cityOption.text }}
                                                            </b-form-checkbox>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-checkbox-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12">
                                            <z-button
                                                type="submit"
                                                :class="{ error: error }"
                                                size="lg"
                                                uppercase
                                                bold
                                                :disabled="isRequesting"
                                            >
                                                <div v-if="isRequesting" class="mt-2">
                                                    <z-loading></z-loading>
                                                </div>
                                                <span v-else-if="error">Revisa tu información</span>
                                                <span v-else>Enviar</span>
                                            </z-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </div>
                            <div class="mt-3">
                                <div
                                    class="z-text-smaller text-left mb-3 d-flex flex-column flex-md-row"
                                >
                                    <div class="mr-md-3">
                                        <router-link :to="{ name: 'aviso-de-privacidad' }">
                                            Aviso de privacidad
                                        </router-link>
                                    </div>
                                    <div>
                                        <router-link :to="{ name: 'terminos-y-condiciones' }">
                                            Términos y Condiciones
                                        </router-link>
                                    </div>
                                </div>
                                <p align="left" class="copyright-label">
                                    © {{ currentYear }} Delivering Geniality SAPI de CV
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ZInput from "@/components/ZInput.vue";
import ZSelect from "@/components/ZSelectBasic.vue";
import Clients from "@/services/clients";
import ServiceType from "@/constants/services/type";
import ServiceCities from "@/constants/services/cities";
import getCurrentYear from "@/utils/getCurrentYear";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";
import Validators from "@/utils/validators";
import ConversionChannel from "@/constants/conversionChannel";
import { BAlert } from "bootstrap-vue";

export default {
    name: "PreRegistration",
    components: {
        BAlert,
        ZInput,
        ZSelect
    },
    data() {
        return {
            Validators,
            error: false,
            businessLineOptions: [
                { text: "Farmacia/Restaurante", value: 0 },
                { text: "eCommerce", value: 1 },
                { text: "Tienda/Retail", value: 2 },
                { text: "Negocio", value: 3 },
                { text: "Otro", value: 4 }
            ],
            email: "",
            name: "",
            phone: "",
            conversionChannel: null,
            conversionChannelOptions: ConversionChannel.options,
            isRequesting: false,
            showErrorAlert: false,
            showSuccessAlert: false,
            serviceTypes: [],
            ServiceType,
            serviceCities: [],
            ServiceCities,
            businessLine: null,
            errorMessage: "Ocurrió un error. Vuelvelo a intentar más tarde."
        };
    },

    computed: {
        currentYear() {
            return getCurrentYear();
        },
        isInputNameValid() {
            return Validators.isNotEmpty(this.name, this.error);
        },
        isInputEmailValid() {
            return Validators.isEmail(this.email, this.error);
        },
        isInputPhoneValid() {
            return Validators.isValidPhone(this.phone, this.error);
        },
        isSelectInputConversionChannelValid() {
            return Validators.isDropdownSelected(this.conversionChannel, this.error);
        },
        isSelectInputBusinessLineValid() {
            return Validators.isDropdownSelected(this.businessLine, this.error);
        }
    },

    watch: {
        error: {
            handler() {
                if (this.error)
                    setTimeout(() => {
                        this.error = false;
                    }, 5000);
            }
        }
    },

    methods: {
        validForm() {
            if (
                this.isInputNameValid &&
                this.isInputEmailValid &&
                this.isInputPhoneValid &&
                this.isSelectInputConversionChannelValid &&
                this.isSelectInputBusinessLineValid
            )
                return true;
            this.error = true;
            return false;
        },

        async handleSubmit() {
            if (!this.validForm()) return;

            this.showErrorAlert = false;
            this.showSuccessAlert = false;
            this.isRequesting = true;
            const {
                email,
                name,
                phone,
                businessLine,
                serviceTypes,
                serviceCities,
                conversionChannel
            } = this;
            const data = {
                email,
                name,
                phone,
                businessLine,
                serviceTypes,
                serviceCities,
                conversionChannel
            };

            Clients.preRegister(data)
                .then(res => {
                    if (res.status && res.status === 401) {
                        throw new Error(res);
                    }
                    this.showSuccessAlert = true;
                    this.cleanForm();
                    trackActionWithAnalytics.facebook("Lead", "contacto");
                    trackActionWithAnalytics.google("formulario", "contacto");
                })
                .catch(error => {
                    this.showErrorAlert = true;
                    this.$captureError(error);
                })
                .finally(() => {
                    this.isRequesting = false;
                });
        },

        cleanForm() {
            this.email = "";
            this.name = "";
            this.phone = "";
            this.businessLine = null;
            this.serviceTypes = [];
            this.serviceCities = [];
            this.conversionChannel = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.error {
    background: $medium-carmine;
}
.form-input {
    margin-top: 15px;
}

.brand-image {
    width: 136px;
    height: 33px;
}

#side-section {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 220px;
    background-image: url("../assets/img/driver-opaque.jpg");

    #side-title {
        font-size: 32px;
    }

    .z-divider {
        height: 1px;
        width: 40px;
        opacity: 0.74;
        background-color: #449aff;
    }

    img {
        width: 80px;
    }
}

.z-label-subline {
    color: #7c7c7c;
    font-size: 12px;
}

.copyright-label {
    color: #8190a1;
    font-size: 12px;
    line-height: 11px;
}

a {
    color: #2c2c2c;
}

@media only screen and (min-width: 768px) {
    #side-section {
        top: 0;
        left: 0;
        position: sticky;
        height: 100vh;

        img {
            width: 100px;
        }
    }
}
</style>

<style lang="scss">
#pre-registration {
    .z-label {
        font-weight: bold;
        font-size: 0.8em;
    }

    .z-checkbox {
        color: $comet;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0.2em;

        .custom-control-label {
            display: flex;
            align-items: center;
        }

        .custom-control-label::before {
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
            border-radius: 0px;
        }
    }
}
</style>
