import { render, staticRenderFns } from "./ZSelectBasic.vue?vue&type=template&id=23cc1667&scoped=true&"
import script from "./ZSelectBasic.vue?vue&type=script&lang=js&"
export * from "./ZSelectBasic.vue?vue&type=script&lang=js&"
import style0 from "./ZSelectBasic.vue?vue&type=style&index=0&id=23cc1667&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23cc1667",
  null
  
)

export default component.exports