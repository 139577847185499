const DELIVERY_MAN_RENT = "Renta de mensajero";
const DELIVERIES_SAME_AND_NEXT_DAY = "Envios same y next day";
const FULFILLMENT = "Fulfillment (almacenaje)";

const NUM_DELIVERY_MAN_RENT = 0;
const NUM_DELIVERIES_SAME_AND_NEXT_DAY = 1;
const NUM_FULFILLMENT = 2;

const options = [
    { value: NUM_DELIVERY_MAN_RENT, text: DELIVERY_MAN_RENT },
    { value: NUM_DELIVERIES_SAME_AND_NEXT_DAY, text: DELIVERIES_SAME_AND_NEXT_DAY },
    { value: NUM_FULFILLMENT, text: FULFILLMENT }
];

export default {
    options,
    NUM_DELIVERY_MAN_RENT,
    NUM_DELIVERIES_SAME_AND_NEXT_DAY,
    NUM_FULFILLMENT,
    DELIVERY_MAN_RENT,
    DELIVERIES_SAME_AND_NEXT_DAY,
    FULFILLMENT
};
